<template>
  <div class="pos-payment-recognize">
    <PageTitle title="第三方交易-瑞乘 ( 銀豹 ) 規則說明" hideBtn />

    <section class="w-full mb-[20px]">
      <p class="title">設定事件行銷範本：消費訂單滿件數</p>
      <div class="container">
        <p style="margin-bottom: 20px">
          若您的第三方事件行銷範本設定：首次完成單筆訂單滿件數、完成單筆訂單滿件數、完成累積訂單滿件數，需留意以下事項
        </p>
        <ul
          class="list-disc flex flex-col mb-[20px]"
          style="padding-left: 30px; gap: 20px"
        >
          <li>
            當訂單的支付方式含有「次卡支付」時，整筆訂單皆不認列入「消費滿件」的事件活動
          </li>
          <li>
            請留意POS出帳品項中含有一項次卡支付，可能會影響會員權益，建議分開結帳
          </li>
        </ul>
        <GrayBlockContainer>
          <p class="text-gray-80 leading-normal">
            舉例：當您在瑞乘POS結帳客戶的消費訂單，包含1筆使用次卡消費（次卡支付）、1筆服務項目、1瓶指緣油。此筆訂單因包含「次卡支付」品項，故整筆訂單不認列至本系統。
          </p>
        </GrayBlockContainer>
      </div>
    </section>
    <section class="w-full mb-[20px]">
      <p class="title">設定會員升降級條件：指定時間消費特定次數</p>
      <div class="container">
        <p style="margin-bottom: 20px">
          當第三方事件行銷設定消費訂單費滿件數時，您可能會建立多筆消費紀錄，因此不建議在設定會員升降級時，選擇「指定時間消費特定次數」的升降等條件。
        </p>
        <GrayBlockContainer>
          <p class="text-gray-80 leading-normal">
            舉例：<br />
            承上，若您同時設定第三方事件行銷-
            消費訂單滿件數活動，也同時設定會員升降級條件「指定時間消費特定次數-2次」，可能會幫消費者建立1筆「次卡支付」訂單、1筆「服務項目+指甲油」訂單。<br />
            此時對升降級來說，使用者已滿足2次條件，就會被升等。此情況會讓升等條件門檻降低，故不建議同時設定。
          </p>
        </GrayBlockContainer>
      </div>
    </section>
    <section class="w-full mb-[20px]">
      <p class="title">支付方式認列</p>
      <div class="container">
        <p style="margin-bottom: 20px">
          以下支付方式皆能認列於事件行銷、會員等級計算
        </p>
        <ul
          class="list-disc flex flex-col mb-[20px] leading-normal"
          style="padding-left: 30px; gap: 20px"
        >
          <li>預設支付方式：現金支付、儲值卡支付</li>
          <li>
            自定義支付方式：轉帳、匯款、信用卡、LINE Pay、Apple Pay、Google
            Pay、Samsung
            Pay、台灣Pay、街口、一卡通Money、全支付、悠遊付、橘子支付、玉山Wallet、icash
            Pay、全盈+Pay、Pi拍錢包、ezPay簡單付
          </li>
          <li>第三方金流支付方式：歐付寶、綠界、藍新、紅陽</li>
        </ul>
      </div>
    </section>

    <section class="w-full mb-[20px]">
      <p class="title">會員等級同步注意事項</p>
      <div class="container">
        <p style="margin-bottom: 20px">
          當啟用會員等級同步功能，會將於歐巴系統設定之會員等級名稱同步至 POS
          端，因此需注意以下事項：
        </p>
        <ul
          class="list-disc flex flex-col mb-[20px] leading-normal"
          style="padding-left: 30px; gap: 20px"
        >
          <li>
            欲手動修改會員等級，請一律至歐巴後台做更改，勿直接於 POS 端進行修改
          </li>
          <li>
            若欲新增會員等級，請確保歐巴後台與瑞乘 POS
            上皆有相同之等級名稱（若等級名稱未能完全對應，同步時將會出現錯誤）
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import GrayBlockContainer from "@/components/Container/GrayBlockContainer.vue"
import { defineComponent } from "vue"
export default defineComponent({
  name: "PosPospal",
  components: { GrayBlockContainer },
  setup(props) {
    return {}
  },
})
</script>

<style lang="postcss" scoped>
.title {
  @apply text-primary-100 font-bold text-md leading-[26px] mb-[10px];
}

.container {
  @apply bg-white p-[20px] max-w-full;
  @apply font-medium text-normal leading-[19.2px] text-gray-100;
}
</style>
